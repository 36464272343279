import React,{ useEffect,useState } from 'react'
import { useLocation } from "@reach/router"
import styled from "styled-components"; 

import SEOMetaData from '../../components/background/SEOMetaData.js';
import {InterestTracker} from "../../components/background/InterestTracker.js";
import GoogleAnalyticsEvent from "../../components/background/GoogleAnalyticsEvent.js";
import { OutboundLink } from "gatsby-plugin-google-gtag"

import Tier3Layout from "../../components/content/Tier3Layout"
import ArticleSidebar from "../../components/content/ArticleSidebar"
import ConsultationRequestTrigger from "../../components/forms/ConsultationRequestTrigger"
import SampleItineraries from "../../components/content/SampleItineraries"
import PageSectionHeadline from "../../components/content/PageSectionHeadline"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Carousel from 'react-bootstrap/Carousel'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { faChevronDoubleRight } from '@fortawesome/pro-solid-svg-icons'
import { faChevronDoubleLeft } from '@fortawesome/pro-solid-svg-icons'

import '../../styles/tier3.css'
import '../../styles/lgbtq.css'
import iglamap_big from '../../images/ENG_ILGA_World_map_sexual_orientation_laws_dec2020.png'; 
import iglamap_small from '../../images/ENG_ILGA_World_map_sexual_orientation_laws_thumb.png'; 
import itinerary_pr_sj_vid from '../../images/sampleitinerary_carousels/lgbtq/lgbtq-pr-sj-vid.jpg'; 
import itinerary_pr_sj_1 from '../../images/sampleitinerary_carousels/lgbtq/lgbtq-pr-sj-1.jpg'; 
import itinerary_pr_sj_2 from '../../images/sampleitinerary_carousels/lgbtq/lgbtq-pr-sj-2.jpg'; 
import itinerary_pr_sj_3 from '../../images/sampleitinerary_carousels/lgbtq/lgbtq-pr-sj-3.jpg'; 
import itinerary_pr_sj_4 from '../../images/sampleitinerary_carousels/lgbtq/lgbtq-pr-sj-4.jpg'; 
import itinerary_pr_sj_5 from '../../images/sampleitinerary_carousels/lgbtq/lgbtq-pr-sj-5.jpg'; 
import itinerary_pr_sj_6 from '../../images/sampleitinerary_carousels/lgbtq/lgbtq-pr-sj-6.jpg'; 
import itinerary_pr_all_1 from '../../images/sampleitinerary_carousels/lgbtq/lgbtq-pr-all-1.jpg'; 
import itinerary_pr_all_2 from '../../images/sampleitinerary_carousels/lgbtq/lgbtq-pr-all-2.jpg'; 
import itinerary_pr_all_3 from '../../images/sampleitinerary_carousels/lgbtq/lgbtq-pr-all-3.jpg'; 
import itinerary_pr_all_4 from '../../images/sampleitinerary_carousels/lgbtq/lgbtq-pr-all-4.jpg'; 
import itinerary_pr_all_5 from '../../images/sampleitinerary_carousels/lgbtq/lgbtq-pr-all-5.jpg'; 
import itinerary_pr_all_6 from '../../images/sampleitinerary_carousels/lgbtq/lgbtq-pr-all-6.jpg'; 
import itinerary_belmond_vid from '../../images/sampleitinerary_carousels/lgbtq/lgbtq-belmond-vid.jpg'; 
import itinerary_belmond_1 from '../../images/sampleitinerary_carousels/lgbtq/lgbtq-belmond-1.jpg'; 
import itinerary_belmond_2 from '../../images/sampleitinerary_carousels/lgbtq/lgbtq-belmond-2.jpg'; 
import itinerary_belmond_3 from '../../images/sampleitinerary_carousels/lgbtq/lgbtq-belmond-3.jpg'; 
import itinerary_belmond_4 from '../../images/sampleitinerary_carousels/lgbtq/lgbtq-belmond-4.jpg'; 
import itinerary_belmond_5 from '../../images/sampleitinerary_carousels/lgbtq/lgbtq-belmond-5.jpg'; 
import allgay_logo_brandg from '../../images/sampleitinerary_carousels/lgbtq/lgbtq-allgay-brandg.png'; 
import allgay_logo_atlantis from '../../images/sampleitinerary_carousels/lgbtq/lgbtq-allgay-atlantis.png'; 
import allgay_logo_rsvp from '../../images/sampleitinerary_carousels/lgbtq/lgbtq-allgay-rsvp.png'; 
import allgay_logo_outadventures from '../../images/sampleitinerary_carousels/lgbtq/lgbtq-allgay-outadventures.png'; 
// import headerimage from '../../images/headerimage-spiritualrestoration.jpg'



const ExpandButton = styled(Button)`
    padding:5px 15px; 
    border:0px; 
    background-color:transparent; 
    &:hover,
    &:active{ 
        background-color:transparent; 
        border:0px; 
    }
`;

const VideoButton = styled(Button)`
    padding:0px; 
    border:0px; 
    background-color:transparent; 
    &:hover,
    &:active{ 
        background-color:transparent; 
        border:0px; 
    }
`;


const Lgbtq = () => {
    const pageData = {
                        headline:{
                            text:<>LGBTQ+ <span className="hidden-xs hidden-sm hidden-md">&amp; Poly-Friendly</span> Vacations</>,
                            iframe:"https://storage.googleapis.com/outward-website-static-resources/page-headings/lgbtq/LGBTQHero.html",
                            hero_height:'450',
                            id:'LGBTQ'
                        },
                        sectionnav:{section:'vacations'},
                        seo:{
                            title:'LGBTQ+ and Poly-Friendly Vacation Planning',
                            description:'We live differently, let\'s travel differently. Outward Travel is a gay-owned travel agency, so it\'s especially important to us to help you design an experience that will be safe, respectful, and welcoming.',
                            image:'/images/socialmedia/socialmediaimage_lgbtq.jpg'
                        }
                    }; 


    let location = useLocation(); 
    useEffect(() => {
        InterestTracker('LGBTQ',location); 
    },[location]);

    const { search } = useLocation()
    const [showConsultationForm,setShowConsultationForm] = useState(false); 
    const [zoomILGAMap,setZoomILGAMap] = useState(false); 
    const [playPRVideo,setPlayPRVideo] = useState(false); 
    const [playBelmondVideo,setPlayBelmondVideo] = useState(false); 

    const [prCarousel1Index, setPrCarousel1Index] = useState(0);
    const [prCarousel2Index, setPrCarousel2Index] = useState(0);
    const [belmondCarousel1Index, setBelmondCarousel1Index] = useState(0);

    const handleCarouselSelect = (section,carousel,selectedIndex) => {
        GoogleAnalyticsEvent("sampleitinerary_carousel_interaction",{section:section,carousel:carousel})
        if(section==='pr'){ 
            if(carousel===1){ 
                setPrCarousel1Index(selectedIndex); 
            } else if(carousel===2){ 
                setPrCarousel2Index(selectedIndex); 
            }
        } else if(section==='belmond'){ 
            if(carousel===1){ 
                setBelmondCarousel1Index(selectedIndex); 
            } 
        }
    };
    
    function startConsultationRequest(which){ 
        GoogleAnalyticsEvent("start_consultationrequest",{buttonLocation:which})
        setShowConsultationForm(true); 
    }
    
    function getStartingPane(){ 
        if(search!==""){ 
            var querystring = search.substring(1); 
            var querystringarray = querystring.split('&'); 
            var thisParam; 
            for(var i=0;i<querystringarray.length;i++){ 
                thisParam = querystringarray[i].split('='); 
                if(thisParam[0]==='view'){ 
                    return thisParam[1]; 
                    // return Itineraries[thisParam[1]]; 
                }
            }
        } else { 
            return ''; 
        }
    }

  return (
    <main>
        <SEOMetaData title={pageData.seo.title} description={pageData.seo.description} image={pageData.seo.image} />
        <Tier3Layout headline={pageData.headline} sectionnav={pageData.sectionnav} showConsultForm={showConsultationForm} onCloseConsultForm={()=>{setShowConsultationForm(false)}}>

            <article>
            <p>We live differently, let's travel differently. We are a diverse people, and inclusive travel opportunities are becoming more available, in more places, every year. Outward Travel is a gay-owned travel agency, so it's especially important to us to help you design an experience that will be safe, respectful, and welcoming. </p>

            <Row>
                <Col lg={8}>
                        <p>What sort of vacation do you like to take? The options depend more on your desires than the location. There are more LGBTQ-only cruises, tours, and destinations now than ever before. You can also have an amazing time in a regular resort, simply knowing you won’t feel harassed or like an outsider. It’s all within reach:</p>
                        <ul>
                            <li>Escape the crowds on an adventurous expedition</li>
                            <li>Fire up the romance in a secluded, luxury destination</li>
                            <li>Make new friends on guided tours or independent journeys </li>
                            <li>Live like a local and celebrate Pride in a different city (or multiple cities!) </li>
                        </ul>
                        <p>With or without kids, many of us have built strong families-of-choice. Friends, throuples, and polycules can all travel together and have an incredible time, forging even stronger bonds and deepening the love and commitment you already have. At Outward Travel, we are ready to help you make the travel arrangements that will respect your family, however you’ve built it. </p>
                        <p>There are a few vacation ideas below, but ultimately we want to know what will make <i>you</i> happy. Let’s have a friendly, no-obligation chat so we can get to know you, make recommendations based on your needs, and ensure you have an awesome vacation. </p>
                        <ConsultationRequestTrigger color="red" onClick={()=>{startConsultationRequest('main_content')}} />
                    </Col>
                    <Col lg={4}>
                        <ArticleSidebar id="LGBTQSafeSidebar" headline={<><span className="hidden-lg">LGBTQ-</span>Safe Countries</>} color="yellow">
                            <p>More countries today welcome our community and have laws protecting our human rights. Check out this map from the IGLA that shows which parts of the world are ready for LGBTQ+ travelers: </p>
                            <p className="clickToExpand">
                                <ExpandButton onClick={()=>{setZoomILGAMap(true); GoogleAnalyticsEvent("expand_ilga_map",{}); }}>
                                    <img src={iglamap_small} alt="ILGA Safety Map" id="ILGAMap" width="100%" />
                                    <FontAwesomeIcon icon={faSearchPlus} size="2x" />
                                </ExpandButton>
                            </p>
                            <h4>Safe-Travel Guides from IGLTA:</h4>
                            <p><OutboundLink href="https://www.iglta.org/LGBTQ-Travel-Safety" target="iglta">LGBTQ+ Safety Guide <FontAwesomeIcon icon={faExternalLinkAlt} size="sm" /></OutboundLink></p>
                            <p><OutboundLink href="https://www.iglta.org/Blog/Travel-Blog/ArtMID/9209/ArticleID/603/Travel-Tips-for-Transgender-Genderqueer-and-Non-Binary-Wanderlusters" target="iglta">Trans* Safety Guide <FontAwesomeIcon icon={faExternalLinkAlt} size="sm" /></OutboundLink></p>
                            <p><OutboundLink href="https://www.iglta.org/Guide-to-Same-Sex-Marriage" target="iglta">Marriage Equality Guide <FontAwesomeIcon icon={faExternalLinkAlt} size="sm" /></OutboundLink></p>
                        </ArticleSidebar>
                        <div className="sidebar">
                        </div>
                    </Col>
            </Row> 
            </article>

            <Modal size="xl" aria-labelledby="igla-modal" centered show={zoomILGAMap} backdrop={true} onHide={()=>{setZoomILGAMap(false)}}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                ILGA: Sexual Orientation Laws Around the World <small>(<OutboundLink href="https://ilga.org/sites/default/files/downloads/ENG_ILGA_World_map_sexual_orientation_laws_dec2020.png" target="_blank">View Bigger</OutboundLink>)</small>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img src={iglamap_big} width="100%" alt="ILGA Safety Map" />
            </Modal.Body>
            </Modal>

            <SampleItineraries id="LGBTQ_Agendas" 
                sectionHeadline={<>LGBTQ+ <span className="hidden-xs hidden-sm hidden-md">Destinations &amp;</span> Vacation Ideas
                    </>} 
                color="blue" 
                displayFirst={getStartingPane()} panes={[
                {
                    headline:"The LGBTQ+ Capital of the Caribbean",
                    linktext:"Puerto Rico",
                    key:"puerto-rico",
                    content:<>
<p>Puerto Rico is the LGBTQ+ capital of the Caribbean, and it’s part of the United States so you don’t need a passport to visit! 
    Exceptionally welcoming to the queer community, Puerto Rico has the most LGBTQ nightlife, de facto gay beaches, <i>two</i> different Pride celebrations, a queer film festival, a music festival, monthly LGBTQ+ celebrations, and tons of LGBTQ+-owned travel businesses are packed into this island that is only 100 miles long. And that doesn’t even include the other 250 beaches, snorkeling & surfing, mountain adventures, casinos, culture and historical sites, shopping, and amazing food that you’ll find here. </p>

                    <Carousel nextIcon={<FontAwesomeIcon icon={faChevronDoubleRight} size="2x" /> } 
                            prevIcon={<FontAwesomeIcon icon={faChevronDoubleLeft} size="2x" /> } 
                            onSelect={(selectedIndex,e)=>{handleCarouselSelect('pr',1,selectedIndex)}} activeIndex={prCarousel1Index} interval={null}>
                        <Carousel.Item>
                            <VideoButton  onClick={()=>{setPlayPRVideo(true); GoogleAnalyticsEvent("play_video_puerto-rico",{}); }}><img className="d-block w-100" src={itinerary_pr_sj_vid} alt="Video of the Condado Vanderbilt in San Juan" /></VideoButton>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_pr_sj_6} alt="Interior of Condado Ocean Club hotel room" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_pr_sj_1} alt="Two men holding hands in Puerto Rico poolchairs" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_pr_sj_2} alt="Gay couple and lesbian couple in Puerto Rico" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_pr_sj_3} alt="Lesbian couple enjoying dinner in San Juan" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_pr_sj_4} alt="Gay couple dancing in San Juan streets" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_pr_sj_5} alt="Gay couple exploring ruins in Puerto Rico" />
                        </Carousel.Item>
                    </Carousel>
<p>You’ll arrive in San Juan, the commonwealth’s capital. Minutes from the airport is the exclusive, adults-only Condado Ocean Club or the more upscale Condado Vanderbilt, at the heart of the city’s luxury district. Unwind with a piña collada (invented in Puerto Rico) by the crystal clear pool before going for an evening stroll along the beaches. Spend the days shopping in exquisite boutiques, enjoying a massage or spa treatment, visiting some of the many museums and art galleries, or exploring the blue cobblestone streets of Old San Juan. Nighttime comes alive here - start with a sunset cocktail on a rooftop bar overlooking the ocean; then start hopping between the many gay bars that cater to every interest - from bear bars to gogo dancing, dive bars to drag shows.</p>

                    <Carousel nextIcon={<FontAwesomeIcon icon={faChevronDoubleRight} size="2x" /> } 
                            prevIcon={<FontAwesomeIcon icon={faChevronDoubleLeft} size="2x" /> } 
                            onSelect={(selectedIndex,e)=>{handleCarouselSelect('pr',2,selectedIndex)}} activeIndex={prCarousel2Index} interval={null}>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_pr_all_1} alt="Two women kissing on a pier in Puerto Rico" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_pr_all_2} alt="Gay couple goofing off by a Puerto Rico lighthouse" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_pr_all_3} alt="Man standing on tank on Flemenco Beach in Puerto Rico" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_pr_all_4} alt="Two women kissing on a Puerto Rico beach" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_pr_all_5} alt="Gay couple playing by a Puerto Rico waterfall" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_pr_all_6} alt="Group exploring bioluminescent waters in Puerto Rico" />
                        </Carousel.Item>
                    </Carousel>
<p>Outside the city there’s even more to see and do—and plenty more beaches with fewer crowds—and it’s just as welcoming to our people as the city. The sister island of Culebra is home to Flemenco Beach, a white-sand beach nestled amongst rolling green hills where the turquoise water is so calm it’s ideal for snorkeling and paddle boarding. The other sister island of Vieques is home to beautiful snow-white beaches and the brightest bioluminescent bay in the world. For the more adventurous type, ride a 1.5-mile-long zipline in the mountains of Orocovis, hike between the 25 different waterfalls of the El Yunge National Forest, or go surfing on the western side of the island. </p>
<p>From lighthouses to Spanish colonial architecture, gay bars to a decadent spa, roaring festivals to art districts, mountain adventure to beachside people watching—Puerto Rico is a slice of tropical paradise that is easy to overlook, but impossible to forget. </p>


                    <p className="legalease">Pictures provided by Puerto Rico Tourism.</p>
                     <ConsultationRequestTrigger color="purple" size="sm" 
                    text={<><span className="hidden-xs hidden-sm">Start Planning Your</span>
                             <span className="hidden-md hidden-lg hidden-xl hidden-xxl">Plan a</span> Purto Rico Getaway</>} 
                    onClick={()=>{startConsultationRequest('sample_puerto-rico')}} />
                    </>
                },
                {
                    headline:"Luxury on the Rails",
                    linktext:"Luxury on the Rails",
                    key:"luxury-on-the-rails",
                    content:<>
<p>If you love the finer things in life, consider a luxury vacation with Belmond trains, hotels, river cruises, and safaris. These experiences aren’t cheap, but neither are you, darling. The epitome of luxury rail—with a hotel portfolio that will astound you—Belmond is very welcoming to queer travelers and has an LGTBQ advisory board to keep them on point. This year even marks their first LGBTQ+-exclusive journey from Venice to Paris. </p>

                    <Carousel nextIcon={<FontAwesomeIcon icon={faChevronDoubleRight} size="2x" /> } 
                            prevIcon={<FontAwesomeIcon icon={faChevronDoubleLeft} size="2x" /> } 
                            onSelect={(selectedIndex,e)=>{handleCarouselSelect('belmond',1,selectedIndex)}} activeIndex={belmondCarousel1Index} interval={null}>
                        <Carousel.Item>
                            <VideoButton  onClick={()=>{setPlayBelmondVideo(true); GoogleAnalyticsEvent("play_video_belmond",{}); }}><img className="d-block w-100" src={itinerary_belmond_vid} alt="Video of the Venice Simplon-Orient-Express" /></VideoButton>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_belmond_1} alt="Grand Suite onboard the Venice Simplon-Orient-Express" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_belmond_2} alt="Conductor outside the Venice Simplon-Orient-Express" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_belmond_3} alt="Bar Car Lounge onboard Belmond train" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_belmond_4} alt="Exterior shot of Belmond train" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_belmond_5} alt="Cabin onboard the Venice Simplon-Orient-Express" />
                        </Carousel.Item>
                    </Carousel>
<p>The golden age of travel continues today with Belmond trains like the Venice Simplon-Orient-Express. With 17 beautifully restored wagon-lit carriages, the train has been lovingly restored and is appointed with exquisite marquetry and art-deco flourishes. Three historic Restaurant Cars offer freshly prepared meals,  and the Bar Car serves as an elegant social hub by day, and a lively piano bar by night – the place to be seen sipping cocktails and singing along to classic tunes as the train sweeps through stunning landscapes.</p>
<p>During the day, your cabin is a lounge, and at night it transforms to a cozy bedroom with upper and lower berths. 
    24-hour cabin service is available by your personal steward. Pack your finest threads, because on a Belmond train 
    you can never be overdressed. And if you’d like an <i>even finer</i> experience, upgrade to a Grand Suite, with 
    interiors inspired by Paris, Venice, and Istanbul. Each Grand Suite is complete with either a double or twin bends, 
    a living area, spacious private ensuite bathroom, free-flowing champagne, caviar on arrival, and impeccable cabin service. </p>

                    <p className="legalease">Pictures provided by Belmond.</p>
                     <ConsultationRequestTrigger color="purple" size="sm" 
                    text={<><span className="hidden-xs hidden-sm">Start Planning Your</span>
                             <span className="hidden-md hidden-lg hidden-xl hidden-xxl">Plan a</span> Luxurious Journey</>} 
                    onClick={()=>{startConsultationRequest('sample_belmond')}} />
                    </>
                },
            ]} />

        <PageSectionHeadline id={`AllGay_Headline`} color="red">All-Gay Vacations</PageSectionHeadline>
        <p>While Outward will always tailor your vacation experience so that you feel safe and included, sometimes 
            an all-gay trip is just what you need to fully relax and have fun. Outward works with a few all-gay tour 
            providers that offer a variety of desintations and travel opportunities.</p>
            <Row className="all-gay-companies" xs={1} sm={2} md={2} lg={2} xl={4} xxl={4}>
                <Col>
                    <Row>
                        <Col className="logo align-middle">
                            <img src={allgay_logo_brandg} width="200" alt="Brand g Vacations" id="AllGayLogo-Brandg"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text">
                        River cruises through Europe & South America. Expeditions to Africa, South America, and Asia.
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col className="logo">
                            <img src={allgay_logo_outadventures} width="200" alt="Out Adventures" id="AllGayLogo-Out"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text">
                        Exepditions through South America, Europe, Asia, Iceland, and Australia. 
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col className="logo">
                            <img src={allgay_logo_atlantis} width="200" alt="Atlantis All-Gay Cruises" id="AllGayLogo-Atlantis"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text">
                        All-gay cruises through the American Tropics, Mediterranean, and Scandanvia, and Caribbean resorts. 
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col className="logo">
                            <img src={allgay_logo_rsvp} width="200" alt="RSVP Vacations" id="AllGayLogo-RSVP"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text">
                        All-gay cruises through tropical destinations. 
                        </Col>
                    </Row>
                </Col>
            </Row>
            <ConsultationRequestTrigger color="green" onClick={()=>{startConsultationRequest('allgay_section')}} />




            <Modal size="lg" aria-labelledby="puerto-rico-video" centered show={playPRVideo} backdrop={true} onHide={()=>{setPlayPRVideo(false)}}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                Condado Vanderbuilt Luxury Hotel
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <iframe className="youtube_video" width="766" height="467" src="https://www.youtube.com/embed/GGRAnXqkYEw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Modal.Body>
            </Modal>

            <Modal size="lg" aria-labelledby="belmond-video" centered show={playBelmondVideo} backdrop={true} onHide={()=>{setPlayBelmondVideo(false)}}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                Belmond Venice Simplon-Orient-Express
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <iframe className="youtube_video" src="https://www.youtube.com/embed/kjNhvuDX8-8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Modal.Body>
            </Modal>

        </Tier3Layout>
    </main>
  )
}


export default Lgbtq;

